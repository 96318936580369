import { ReactComponent as XIcon } from "assets/images/icons/icon-x.svg";
import s from "./closeButton.module.scss";

interface CloseButtonProps {
  onCloseClick?: (event: any) => void,
}

export const CloseButton = ({
  onCloseClick
}: CloseButtonProps) => {
  return <XIcon className={s.closeButton} onClick={onCloseClick}/>;
};
