import { FC } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from 'components/ui/button/button';
import { Field } from "components/ui/field/field";
import styles from "./projectForm.module.scss";

export interface ProjectFormFields {
  projectName: string;
}

export const ProjectFormComponent: FC = () => {

  const { register, handleSubmit, formState: { errors }, watch } = useForm<ProjectFormFields>();

  const handleOptionChange = (event: any) => {
    console.log("change option = ", event.value);
  };

  const onSubmit = async (values: ProjectFormFields) => {
    //  Do nothing
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>

      <label>PROJECT</label>
      <hr className={styles.solid}></hr>

      <Field
        labelName={'Project Type'}
        fieldName={'projectType'}
        fieldType={'dropDown'}
        options={[]}
        handleOptionChange={handleOptionChange}
        customClassName={styles.halfLength}
      />

      <Field
        labelName={'Project Title'}
        fieldName={'projectTitle'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Field
        labelName={'Project URL'}
        fieldName={'projectURL'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Field
        labelName={'Tags'}
        fieldName={'projectTags'}
        fieldType={'textArea'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Field
        labelName={'Lead Consultant'}
        fieldName={'leadConsultant'}
        fieldType={'dropDown'}
        options={[]}
        handleOptionChange={handleOptionChange}
        customClassName={styles.halfLength}
      />

      <Field
        labelName={'Prepared For'}
        fieldName={'preparedFor'}
        fieldType={'dropDown'}
        options={[]}
        handleOptionChange={handleOptionChange}
        customClassName={styles.halfLength}
      />

      <Field
        labelName={'Project Status'}
        fieldName={'projectStatus'}
        fieldType={'dropDown'}
        options={[]}
        handleOptionChange={handleOptionChange}
        customClassName={styles.halfLength}
      />

      <Field
        labelName={'Project Notes'}
        fieldName={'projectNoteds'}
        fieldType={'textArea'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Button className={styles.submitButton} type="submit">
        Save
      </Button>
    </form>
  );
};

export const ProjectForm = withRouter(ProjectFormComponent);
