import { ReactNode } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./button.module.scss";

export enum ButtonStyle {
  Primary = "primary",
  Secondary = "secondary",
  Confirm = "confirm",
  Alert = "alert",
  Cancel = "cancel",
  None = "none"
}

interface IButtonProps {
  children?: ReactNode | string;
  style?: ButtonStyle;
  className?: string;
  onClick?: (event: any) => void;
  disabled?: boolean;
  type?: "submit" | "button" | "reset" | undefined;
  icon?: string;
  iconPosition?: "left" | "right",
  id?: string
}

interface LinkButtonProps extends IButtonProps {
  to: string;
}

export const LinkButton = ({
  to,
  style = ButtonStyle.Primary,
  children,
  className,
}: LinkButtonProps) => {
  return (
    <Link
      to={to}
      className={classNames(
        styles.linkButton,
        style && styles[style],
        className
      )}
    >
      {children}
    </Link>
  );
};

export const Button = ({
  style = ButtonStyle.Primary,
  children,
  className,
  onClick = () => undefined,
  disabled = false,
  type = "button",
  icon,
  iconPosition,
  id,
}: IButtonProps) => {
  if (style === ButtonStyle.None) {
    return (
      <div
        className={classNames(styles.noneButton, className)}
        tabIndex={0}
        id={id}
        role="button"
        {...{
          disabled,
          onClick,
          onKeyPress: onClick,
          type,
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <button
      id={id}
      className={classNames(styles.button, style && styles[style], className, icon && iconPosition ? style && styles[`iconButton-${iconPosition}`] : ``)}
      {...{
        disabled,
        onClick,
        type,
      }}
    >
      {icon && iconPosition ? (
        <img className={classNames(styles.buttonIcon)} src={icon}></img>
      ) : (
        <></>
      )}
      {children}
    </button>
  );
};
