import React, { ReactNode, useEffect, useState } from "react";
import styles from "./tableWrapper.module.scss";
import { User, UserGroup } from "actions/lib/types";
import { DataList } from "components/ui/dataList/dataList";
import { DataTable } from "components/ui/dataTable/dataTable";

export enum TableType {
  Table,
  List,
}

interface TableWrapperProps {
  tableData: Array<UserGroup | User | any>;
  tableHeaders?: Array<ReactNode>;
  tableType: TableType;
}

const _renderTableType = (tableData: any, tableType: any, tableHeaders?: Array<ReactNode>) => {
  switch (tableType) {
    case TableType.Table:
      return <DataTable tableData={tableData} tableHeaders={tableHeaders} />;
    case TableType.List:
      return <DataList tableData={tableData} />;
  }
};

export const TableWrapper = ({ tableData, tableType, tableHeaders }: TableWrapperProps) => {
  return (
    <div className={styles.tableContainer}>
      {_renderTableType(tableData, tableType, tableHeaders)}
    </div>
  );
};
