import Layout from "components/layout/Layout";
import styles from "./admin.module.scss";
import { Header } from "components/shared/header/header";
import { AppTile } from "./components/appTile/appTile";
import { RoutePaths } from "app/routing/routing";

export const Admin = () => {

  const mockData = [
    { name: "NHS Rules", route: RoutePaths.Admin },
    { name: "Projects & Documents", route: RoutePaths.StudyForm },
    { name: "Organizations", route: RoutePaths.Organization }
  ];

  return (
    <Layout>
      <div className={styles.siteCheckupHeader}>
        <Header
          headerName={"District Admin"}
        />
      </div>
      <div className={styles.appContainer}>
        {mockData.map((app, index) => {
          return <AppTile key={index} app={app} />;
        })}
      </div>
    </Layout>
  );
};
