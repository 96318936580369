import React, { FC, useEffect, useState } from "react";
import { MapLayer as MapLayerType, MapLayerGroup as MapLayerGroupType, LabelValuePair } from "actions/lib/types";
import { fetchMapColours, fetchMapLayers } from "actions/mapActions";

const UseMapHook: any = () => {
  const controlPanelTabs = ['Context Map', 'Layers', 'Files'];
  enum pageTabs {
    mapEditting = 'Map Editing',
    ndaAnalysis = 'NDA Analysis',
    areaAnalytics = 'Area Analytics',
  }
  const editMapLayerData = {
    name: 'Brampton Natural Heritage',
    boxColor: 'lime',
    boxBorderColor: 'green',

  };

  const [selectedTab, setSelectedTab] = useState<string>('');
  const [selectedNavTab, setSelectedNavTab] = useState<string>('');
  const [isFirstTabSelected, setIsFirstTabSelected] = useState<boolean>(true);
  const [isLastTabSelected, setIsLastTabSelected] = useState<boolean>(false);
  const [editLayer, setEditLayer] = useState<string>('');
  const [opacity, setOpacity] = useState<number>(50);
  const [fillColour, setFillColour] = useState<string>('#15B932');
  const [borderColour, setBorderColour] = useState<string>('#1C9C03');
  const [borderWidth, setBorderWidth] = useState<number>(1);
  const [layerList, setLayerList] = useState<Array<MapLayerType | MapLayerGroupType>>([]);
  const [colourList, setColourList] = useState<Array<LabelValuePair>>([]);

  const updateLayerList = async () => {
    try {
      const result = await fetchMapLayers();
      result != null ? setLayerList(result) : setLayerList([]);
    } catch (error) {
      console.error('Error fetching Map Layers:', error);
      setLayerList([]);
    }
  };

  const updateColourList = async () => {
    try {
      const result = await fetchMapColours();
      result != null ? setColourList(result) : setColourList([]);
    } catch (error) {
      console.error('Error fetching Map Colours:', error);
      setColourList([]);
    }
  };

  const onTabClick = (event: any) => {
    const index = event.currentTarget.id.split('-')[1];
    setSelectedTab(controlPanelTabs[index]);
    if(parseInt(index) === 0) {
      setIsFirstTabSelected(true);
      setIsLastTabSelected(false);
    } else if (parseInt(index) === controlPanelTabs.length - 1) {
      setIsFirstTabSelected(false);
      setIsLastTabSelected(true);
    } else {
      setIsFirstTabSelected(false);
      setIsLastTabSelected(false);
    }
  };

  const onNavTabClick = (event: any) => {
    const id = event.currentTarget.id.split('-')[1];
    setSelectedNavTab(id);
  };

  const onLeftArrowClick = () => {
    const foundTab = controlPanelTabs.indexOf(selectedTab || "") - 1;
    if(foundTab >= 0) {
      setSelectedTab(controlPanelTabs[foundTab]);
    } 
    if(foundTab === 0) {
      setIsFirstTabSelected(true);
      setIsLastTabSelected(false);
    } else if (foundTab === controlPanelTabs.length - 1) {
      setIsFirstTabSelected(false);
      setIsLastTabSelected(true);
    } else {
      setIsFirstTabSelected(false);
      setIsLastTabSelected(false);
    }
  };

  const onRightArrowClick = () => {
    const foundTab = controlPanelTabs.indexOf(selectedTab || "") + 1;
    if(foundTab < controlPanelTabs.length) {
      setSelectedTab(controlPanelTabs[foundTab]);
    } 
    if(foundTab === 0) {
      setIsFirstTabSelected(true);
      setIsLastTabSelected(false);
    } else if (foundTab === controlPanelTabs.length - 1) {
      setIsFirstTabSelected(false);
      setIsLastTabSelected(true);
    } else {
      setIsFirstTabSelected(false);
      setIsLastTabSelected(false);
    }
  };

  const onEditClick = (event: any) => {
    if(event.target.id === editLayer) {
      // Click the same one then close it
      setEditLayer('');
    } else {
      // Change edit to another layer
      setEditLayer(event.target.id);
    }
  };

  const onChangeOpacity = (event: any) => {
    setOpacity(event.target.value);
  };

  return {
    selectedTab,
    setSelectedTab,
    selectedNavTab,
    setSelectedNavTab,
    isFirstTabSelected,
    setIsFirstTabSelected,
    isLastTabSelected,
    setIsLastTabSelected,
    editLayer,
    setEditLayer,
    opacity,
    setOpacity,
    fillColour,
    setFillColour,
    borderColour,
    setBorderColour,
    borderWidth,
    setBorderWidth,
    layerList,
    setLayerList,
    colourList,
    setColourList,
    updateLayerList,
    updateColourList,
    onTabClick,
    onNavTabClick,
    onLeftArrowClick,
    onRightArrowClick,
    onEditClick,
    onChangeOpacity,
    controlPanelTabs,
    pageTabs,
    editMapLayerData,
  };
};

export default UseMapHook;