import React, { FC, useEffect, useState } from "react";
import { Project, ReferenceDocumentType, ReferenceDocumentStatus, Organization, StudyAreaBoundary  } from "actions/lib/types";
import { fetchProjects, fetchReferenceDocumentTypes, fetchReferenceDocumentStatuses, fetchOrganizations, fetchStudyAreaBoundaries } from "actions/adminActions";

const UseAdminHook: any = () => {
  const controlPanelTabs = ['A', 'B'];
  enum pageTabs {
    docs = 'Docs',
    project = 'Project',
    organization = 'Organization'
  }

  const [selectedTab, setSelectedTab] = useState<string>('');
  const [selectedNavTab, setSelectedNavTab] = useState<string>('');
  const [isFirstTabSelected, setIsFirstTabSelected] = useState<boolean>(true);
  const [isLastTabSelected, setIsLastTabSelected] = useState<boolean>(false);
  const [projectList, setProjectList] = useState<Array<Project>>([]);
  const [referenceDocumentTypeList, setReferenceDocumentTypeList] = useState<Array<ReferenceDocumentType>>([]);
  const [referenceDocumentStatusList, setReferenceDocumentStatusList] = useState<Array<ReferenceDocumentStatus>>([]);
  const [organizationList, setOrganizationList] = useState<Array<Organization>>([]);
  const [studyAreaBoundaryList, setStudyAreaBoundaryList] = useState<Array<StudyAreaBoundary>>([]);

  const updateProjectList = async () => {
    try {
      const result = await fetchProjects();
      result != null ? setProjectList(result) : setProjectList([]);
    } catch (error) {
      console.error('Error fetching Projects:', error);
      setProjectList([]);
    }
  };

  const updateReferenceDocumentTypeList = async () => {
    try {
      const result = await fetchReferenceDocumentTypes();
      result != null ? setReferenceDocumentTypeList(result) : setReferenceDocumentTypeList([]);
    } catch (error) {
      console.error('Error fetching ReferenceDocumentTypes:', error);
      setReferenceDocumentTypeList([]);
    }
  }

  const updateReferenceDocumentStatusList = async () => {
    try {
      const result = await fetchReferenceDocumentStatuses();
      result != null ? setReferenceDocumentStatusList(result) : setReferenceDocumentStatusList([]);
    } catch (error) {
      console.error('Error fetching ReferenceDocumentStatuses:', error);
      setReferenceDocumentStatusList([]);
    }
  }

  const updateOrganizationList = async () => {
    try {
      const result = await fetchOrganizations();
      result != null ? setOrganizationList(result) : setOrganizationList([]);
    } catch (error) {
      console.error('Error fetching Organizations:', error);
      setOrganizationList([]);
    }
  };

  const updateStudyAreaBoundaryList = async () => {
    try {
      const result = await fetchStudyAreaBoundaries();
      result != null ? setStudyAreaBoundaryList(result) : setStudyAreaBoundaryList([]);
    } catch (error) {
      console.error('Error fetching StudyAreaBoundaries:', error);
      setStudyAreaBoundaryList([]);
    }
  }

  const onTabClick = (event: any) => {
    const index = event.currentTarget.id.split('-')[1];
    setSelectedTab(controlPanelTabs[index]);
    if (parseInt(index) === 0) {
      setIsFirstTabSelected(true);
      setIsLastTabSelected(false);
    } else if (parseInt(index) === controlPanelTabs.length - 1) {
      setIsFirstTabSelected(false);
      setIsLastTabSelected(true);
    } else {
      setIsFirstTabSelected(false);
      setIsLastTabSelected(false);
    }
  };

  const onNavTabClick = (event: any) => {
    const id = event.currentTarget.id.split('-')[1];
    setSelectedNavTab(id);
  };

  return {
    selectedTab,
    setSelectedTab,
    selectedNavTab,
    setSelectedNavTab,
    isFirstTabSelected,
    setIsFirstTabSelected,
    isLastTabSelected,
    setIsLastTabSelected,
    projectList,
    setProjectList,
    updateProjectList,
    referenceDocumentTypeList,
    setReferenceDocumentTypeList,
    updateReferenceDocumentTypeList,
    referenceDocumentStatusList,
    setReferenceDocumentStatusList,
    updateReferenceDocumentStatusList,
    organizationList,
    setOrganizationList,
    updateOrganizationList,
    studyAreaBoundaryList,
    setStudyAreaBoundaryList,
    updateStudyAreaBoundaryList,
    onTabClick,
    onNavTabClick,
    pageTabs
  };
};

export default UseAdminHook;