import { useState, useCallback, useEffect, ReactNode, FC } from "react";
import { SingleDateInput } from './SingleDateInput/singleDateInput';
import { RangeInput } from './RangeInput/rangeInput';
import { RadioChoice } from "components/ui/radioChoice";
import styles from './dateTime.module.scss';
import classNames from "classnames";

const TimeTypes = {
  Date: { type: 'date', intlKey: 'report.o-date' },
  TimeRange: { type: 'timeRange', intlKey: 'report.o-timeRange' }
}

interface DateTimeProps {
  label?: ReactNode;
  required?: boolean;
  className?: string;
  groupName: string;
  isRange?: boolean;
  startName?: string;
  endName?: string;
  value?: Array<Date>;
  onChange?: (value: Array<Date>) => void;
  errorMessage?: ReactNode;
  showTimeSelect?: boolean;
  dateFormat?: string
}

export const DateTime = ({ label, required, groupName, isRange = false, startName = 'startDate', endName = 'endDate', value = [], onChange = () => { console.log("onChanage") }, errorMessage, showTimeSelect, dateFormat }: DateTimeProps) => {

  const formattedOptions = Object.entries(TimeTypes).map(
    ([k, value]) => {
      return {
        value: value.type,
        text: "NONE"
      };
    }
  );

  // On initial creation,
  // if there are 2 dates, start with 
  const dateOrRangeDefault = "null";
  const [dateOrRange, setDateOrRange] = useState(dateOrRangeDefault);

  const onOptionSelect = (optionSelected: string) => {
    setDateOrRange(optionSelected);
  }

  const [dateTimeVal, setDateTime] = useState(value);

  useEffect(() => {
    if (value?.length) {
      setDateTime(value);
      setDateOrRange((value.length > 1) ? TimeTypes.TimeRange.type : TimeTypes.Date.type);
    }
  }, []);

  const setStartTime = useCallback((newDate) => {
    if (dateTimeVal && (dateTimeVal.length > 0) && (dateOrRange === TimeTypes.TimeRange.type)) {
      const newDateTime = [...(dateTimeVal)];

      newDateTime[0] = newDate;
      setDateTime(newDateTime);
      onChange(newDateTime);
    } else {
      const newValue = [newDate];
      setDateTime(newValue);
      onChange(newValue);
    }
  }, [setDateTime, dateTimeVal, onChange, dateOrRange]);

  const setEndTime = useCallback((endDate) => {
    const newDateTime = [...dateTimeVal];

    if (newDateTime.length === 0) {
      const newDateVal = [null, endDate];
      setDateTime(newDateVal);
      onChange(newDateVal);
    } else if (newDateTime.length >= 1) {
      const newDateVal = [dateTimeVal[0], endDate];
      setDateTime(newDateVal);
      onChange(newDateVal);
    }

  }, [setDateTime, dateTimeVal, onChange]);

  const checkForInputType = (type: string) => {
    switch (type) {
      case TimeTypes.Date.type:
        return (
          <SingleDateInput
            value={(dateTimeVal?.length > 0) ? dateTimeVal[0] : undefined}
            inputName={startName}
            onChange={(date) => { setStartTime(date); }}
            showTimeSelect={showTimeSelect}
            dateFormat={dateFormat}
          />);
      case TimeTypes.TimeRange.type:
        return (
          <RangeInput
            startName={startName}
            endName={endName}
            values={dateTimeVal}
            onStartChange={(date) => { setStartTime(date); }}
            onEndChange={(date) => { setEndTime(date); }}
          />);
    }
  }

  const DatePickerRange: FC<object> = () => {
    return (
      <div className={styles.dateTime}>
        <RadioChoice
          label={label}
          groupName={groupName}
          value={dateOrRange}
          onChange={onOptionSelect}
          options={formattedOptions}
          required={required}
          className={styles.dateRangeType}
        />
        {checkForInputType(dateOrRange)}
      </div>
    );
  }

  const DatePickerSingle: FC<object> = () => {
    return (
      <div className={classNames(styles.dateTime, styles.singleDateTime)}>
        <p className={styles.dateTimeLabel}>
          {label}
          {required && (<span className={styles.required}>*</span>)}
        </p>
        <SingleDateInput
          value={(dateTimeVal?.length > 0) ? dateTimeVal[0] : undefined}
          inputName={startName}
          onChange={(date) => { setStartTime(date); }}
          showTimeSelect={showTimeSelect}
          dateFormat={dateFormat}
        />
      </div>
    );
  }

  return (
    <div className={classNames(errorMessage && styles.errorField)}>
      {isRange ? <DatePickerRange /> : <DatePickerSingle />}
      {errorMessage && (
        <p className={styles.errorMessage}>{errorMessage}</p>
      )}
    </div>
  );
}
