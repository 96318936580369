import { FC } from "react";

import styles from "./heading.module.scss";

const Heading: FC = ({ children }) => {
  return (
    <h2>{children}</h2>
  );
};

export default Heading;
