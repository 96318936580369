import { useState,  useEffect, useRef, useCallback } from "react";
import { UploadFileType,  uploadFiles, getLatestFile  } from 'actions/uploadActions';
import Layout from "components/layout/Layout";
import { Button } from "components/ui/button/button";


// A Dirty almost-unique File key based on its properties.  Although not guaranteed to be unique, it's close enough.
const getFileKey = (file: File) => {
  return `name-${file.name}-lastModified-${file.lastModified}-size-${file.size}-type-${file.type}`;
};


export const Upload = () => {

  const [fileList, setFileList] = useState<UploadFileType[]>([]);
  const [uploadGroupId] = useState<number>(1);  
  const [latestFileUrl, setLatestFileUrl] = useState("");
  const attachmentInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => {
      try {
        
        const fileResult = await getLatestFile(uploadGroupId);
        setLatestFileUrl(fileResult.url);
      } catch (err) {
        console.log(err);
      }   
    })();
  });

  const handleSelectedImages = useCallback((event) => {
    const {
      currentTarget: { files },
    } = event; 
    const fileArray: File[] = Array.from(files);
    const attachmentsToAdd: Array<UploadFileType> = fileArray.map((file: File) => {
      return {
        key: getFileKey(file),
        name: file.name,
        uploaded: false,
        file: file,
      };
    });
    setFileList(attachmentsToAdd);
  }, []);


  const onUpload = async () => {
    try {

      await uploadFiles(uploadGroupId,  fileList);   
      const input = document.getElementById("file-upload") as HTMLInputElement;
      input.value = '';
      setFileList([]);

    } catch (err) {
      console.log(err);
    }
  };
  

  return (
    <Layout>
      <div className="App">
        <header className="App-header">
          <p>This Page will be for testing Upload to S3 buskets</p>
          <img src={latestFileUrl} alt="file from s3 bucket" width="500"/>
          
          <label>Upload file</label>
          <input
            id="file-upload"
            type="file" 
            accept="image/*, audio/*, video/*, .pdf, .heic"
            onChange={ (event) => {  handleSelectedImages(event);	}}
            ref={attachmentInput}
          />
          <Button onClick={() => { onUpload(); }} >Upload</Button>
        </header>
      </div>
    </Layout>
  );
};
