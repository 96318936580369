
export const baseAPIUrl = process.env.REACT_APP_API_URL;
export const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

// Jwt specific data 
export const jwtData = { 
  refreshUrl: 'refreshAuth',
  authToken: 'authToken',
  refreshToken: 'refreshToken',
  authTokenExpiry: 'authTokenExpiry',
  refreshTokenExpiry: 'refreshTokenExpiry',
  expiryOffset: 60*1000,
};

export const apiEndpoints = {
  login: 'login',
  users: 'users',
  usersMe: 'users/me',
  healthCheckApi: 'healthCheck/api',
  healthCheckDatabase: 'healthCheck/database',
  register: 'register'
};
