import { useEffect, useState } from 'react';
import Layout from "components/layout/Layout";
import styles from "./study.module.scss";
import { Header } from "components/shared/header/header";
import { ReferenceDocumentForm } from "components/referenceDocumentForm/referenceDocumentForm";
import { ProjectForm } from "components/projectForm/projectForm";
import { Tab } from "components/tab/tab";
import UseAdminHook from "../../adminHook";

export const Study = () => {

  const { pageTabs, onNavTabClick, selectedNavTab } = UseAdminHook();

  console.log(selectedNavTab);

  return (
    <Layout>
      <div className={styles.studyHeader}>
        <Header
          headerName={"Study Form"}
        />
      </div>
      <div className={styles.navigationContainer}>
        <Tab icon={null} tabLabel={pageTabs.docs} onClick={onNavTabClick} isSelected={selectedNavTab === pageTabs.docs || selectedNavTab === ""} />
        <Tab icon={null} tabLabel={pageTabs.project} onClick={onNavTabClick} isSelected={selectedNavTab === pageTabs.project} />
      </div>
      <div className={styles.appContainer}>
        {(selectedNavTab === "" || selectedNavTab === pageTabs.docs) &&
          <ReferenceDocumentForm />
        }

        {selectedNavTab === pageTabs.project &&
          <ProjectForm />
        }
      </div>
    </Layout>
  );
};
