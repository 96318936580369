import { User } from "actions/lib/types";
import { Button, ButtonStyle } from "components/ui/button/button";
import {
  DropdownOption,
  OptionDropdown,
} from "components/ui/optionDropdown/optionDropdown";
import { UserInfo } from "components/ui/userInfo/userInfo";
import styles from "./dataList.module.scss";
import cn from "clsx";
import { ReactComponent as DropdownIcon } from "assets/images/icons/icon-dropdown-arrow.svg";
import { ReactComponent as CheckIcon } from "assets/images/icons/icon-check.svg";

export const DataList = ({ tableData }: any) => {
  const handleUserRoleChange = (option: DropdownOption) => {
    console.log("Role:", option.value);
  };

  return (
    <table className={cn(styles.table, styles.userTable)}>
      <tbody>
        {tableData?.map((rowItem: User, index: number) => {
          return (
            <tr key={index}>
              <td>
                <UserInfo key={index} userInfo={rowItem} showUserName={true} />
              </td>
              <td>
                <div>
                  <OptionDropdown
                    label={""}
                    className={styles.roleDropdown}
                    options={[
                      {
                        value: "projectManager",
                        label: "Project Manager",
                      },
                      {
                        value: "mapEditor",
                        label: "Map Editor",
                      },
                    ]}
                    handleOptionChange={handleUserRoleChange}
                    dropdownIcon={<DropdownIcon />}
                  />
                </div>
              </td>
              <td>
                <div className={styles.textCenter}>
                  <Button style={ButtonStyle.None}>
                    <CheckIcon />
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
