import { Organization } from "actions/lib/types";

export const _mockDataForOrganizations: Array<Organization> = [
  {
    id: 1,
    type: 1,
    name: "Organization 1",
  },
  {
    id: 2,
    type: 2,
    name: "Organization 2",
  },
  {
    id: 3,
    type: 2,
    name: "Organization 3",
  },
];
