import React, { ReactNode, useEffect, useState } from "react";
import cn from "clsx";
import { ModalWrapper } from "components/ui/modalWrapper/modalWrapper";
import Title from "components/ui/typograhpy/title/title";
import TextField from "components/ui/textField/textField";
import { useForm } from "react-hook-form";
import { Button, ButtonStyle } from "components/ui/button/button";
import styles from "./nestedModal.module.scss";
import { ReactComponent as XIcon } from "assets/images/icons/icon-x.svg";

export interface NestedModalData {
  nestedDataGroup: any;
  nestedData: Array<any>;
}
interface NestedModalProps {
  tableTitle: string;
  isOpen: boolean;
  nestedData: Array<NestedModalData>;
  handleCloseTable: (value: boolean) => void;
  ctaButtonLabel?: string;
  handleCTAButton?: () => void;
  nestedDataGroupRender?: ReactNode;
  nestedDataRender?: ReactNode;
}

export interface NestedModalFormFields {
  tableField: string;
}

export const NestedModal = ({
  tableTitle,
  isOpen,
  handleCloseTable,
  ctaButtonLabel,
  handleCTAButton,
  nestedDataGroupRender,
  nestedDataRender,
}: NestedModalProps) => {
  return (
    <ModalWrapper isModalOpen={isOpen} className={styles.nestedModalContainer}>
      <div className={styles.modalHeader}>
        <div className={styles.titleContainer}>
          <div className={styles.rowGapped}>
            <Title>{tableTitle}</Title>
            {ctaButtonLabel && (
              <Button style={ButtonStyle.Secondary} onClick={handleCTAButton}>
                {ctaButtonLabel}
              </Button>
            )}
          </div>
          <Button
            style={ButtonStyle.None}
            onClick={() => {
              handleCloseTable(false);
            }}
          >
            <XIcon />
          </Button>
        </div>
      </div>
      <div className={styles.modalDataContainer}>
        <div className={styles.nestedDataGroupColumn}>
          {nestedDataGroupRender}
        </div>
        <div className={styles.nestedDataColumn}>{nestedDataRender}</div>
      </div>
    </ModalWrapper>
  );
};
