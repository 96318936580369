import { ReactNode } from "react";
import s from "./sliderSelect.module.scss";
import cn from 'clsx';
import BodyText from "../typograhpy/bodyText/bodyText";

interface SliderSelectProps {
  label?: string,
  value: number,
  onChangeValue: (event: any) => void,
  sliderClass?: string
}

export const SliderSelect = ({
  label,
  value,
  onChangeValue,
  sliderClass,
}: SliderSelectProps) => {
  
  return (
    <div className={s.sliderContainer}>
      <BodyText className={s.label}>{label && label}</BodyText>
      <input className={cn(s.slider, sliderClass)} type="range" min="0" max="100" value={value} onChange={onChangeValue} />
      <p className={s.sliderValue}>{value}%</p>
    </div>
  );
};
