import React from 'react';
import styles from './footer.module.scss';

// Footer for all pages
// Dumb component, so use stateless Function declaration
// no props
const Footer = () => {
  return (
    <footer className={styles.footer}>
    </footer>
  );
};

export default Footer;
