import React, { useEffect, useState } from "react";
import Layout from "components/layout/Layout";
import styles from "./projects.module.scss";
import { Header } from "components/shared/header/header";
import { ProjectTile } from "./components/projectTile/projectTile";

export const Projects = () => {
  const [isModalAddProjectOpen, setIsModalAddProjectOpen] =
    useState<boolean>(false);
  const projectFilterOptions = [
    {
      label: "Internal",
      value: "internal",
    },
    {
      label: "Shared",
      value: "shared",
    },
  ];

  const projectSortOptions = [
    {
      label: "Newest",
      value: "newest",
    },
    {
      label: "Oldest",
      value: "oldest",
    },
  ];

  const mockData = [{}, {}, {}, {}, {}];

  const handleAddProject = () => {
    setIsModalAddProjectOpen(true);
  };

  return (
    <Layout>
      <div className={styles.siteCheckupHeader}>
        <Header
          headerName={"Projects"}
          filterOptions={projectFilterOptions}
          sortOptions={projectSortOptions}
          ctaString={`+ Add Project`}
          handleCTA={handleAddProject}
          hasSearch={true}
        />
      </div>
      <div className={styles.projectsContainer}>
        {mockData.map((project, index) => {
          return <ProjectTile key={index} project={project} />;
        })}
      </div>
    </Layout>
  );
};
