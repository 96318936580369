import cn from "clsx";
import { ReactNode } from "react";
import { ReactComponent as DropdownArrow } from "assets/svgs/dropdown-arrow.svg";
import s from "./simpleDropdown.module.scss";

interface SimpleDropdownProps {
  title: string;
  options: Array<{ option: ReactNode }>;
}

export const SimpleDropdown = ({ title, options }: SimpleDropdownProps) => {
  return (
    <div className={s.dropdown}>
      <button className={cn(s.dropdownButton, "cypressNavDropdown")}>
        {title}
        <DropdownArrow className={cn(s.dropdownArrow, "cypressNavdropdownArrow")}/>
      </button>
      <div>
        <div className={cn(s.dropdownContent, "cypressNavDropDownContent")}>
          {options.map((option) => {
            return option.option;
          })}
        </div>
      </div>
    </div>
  );
};
