import { ReactComponent as LeftArrow } from "assets/images/icons/icon-left-chevron.svg";
import { ReactComponent as RightArrow } from "assets/images/icons/icon-right-chevron.svg";
import cn from 'clsx';
import { ReactNode } from "react";
import s from "./panelHeader.module.scss";

interface PanelHeaderProps {
  onLeftArrowClick?: () => void,
  onRightArrowClick?: () => void,
  children?: ReactNode,
  isFirstTab: boolean,
  isLastTab: boolean,
  childrenClass?: string,
}

export const PanelHeader = ({
  onLeftArrowClick,
  onRightArrowClick,
  children,
  isFirstTab,
  isLastTab,
  childrenClass
}: PanelHeaderProps) => {
  return (
    <>
      <LeftArrow onClick={onLeftArrowClick} className={cn(s.leftArrow, isFirstTab ? null : s.bold)} />
      <div className={childrenClass}>
        {children}
      </div>
      <RightArrow onClick={onRightArrowClick} className={cn(s.rightArrow, isLastTab ? null : s.bold)} />
    </>
  );
};