import DatePicker from "react-datepicker";
import { useState, forwardRef } from "react";
import classNames from "classnames";
import styles from './singleDateInput.module.scss';
import "react-datepicker/dist/react-datepicker.css";
import textFieldStyles from '../../textField/textField.module.scss';

interface DateProps {
  showTimeSelect?: boolean;
  inputName: string;
  onChange?: (event: any) => void;
  value?: Date,
  filterSelectableDate?: (date: Date) => boolean,
  dateFormat?: string
}

interface CustomInputProps {
  value?: any;
  onClick?: any;
  name?: string;
  type?: string;
  ref?: any;
  className?: string;
}

const CustomInput = forwardRef(({ value, onClick, name, className }: CustomInputProps, _ref) => {
  return <div className={classNames(
    styles.inputContainer)}>
    <input
      onClick={onClick}
      value={value}
      readOnly
      name={name}
      placeholder={'MM/DD/YYYY'}
      type="text"
      className={classNames(textFieldStyles.textInput, className)}
    />
  </div>
});

export const SingleDateInput = ({ dateFormat = "MMMM d, yyyy h:mm aa", showTimeSelect = true, inputName = "date", onChange = () => { console.log("SingleDateInput") }, value, filterSelectableDate }: DateProps) => {
  const [dateSelected, setDateSelected] = useState<Date | null>(value || null);

  return (
    <>
      <div className={styles.singleDateInput}>
        <DatePicker
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className={styles.inputContainer}
          selected={dateSelected}
          onChange={(date) => {
            setDateSelected(date);
            onChange(date);
          }}
          popperModifiers={{
            preventOverflow: {
              enabled: true,
            },
          }}
          showPopperArrow={false}
          showTimeSelect={showTimeSelect}
          timeIntervals={30}
          dateFormat={dateFormat}
          customInput={<CustomInput />
          }
        />
      </div>
    </>
  );
};
