import React from "react";
import styles from "./profileIcon.module.scss";
import cn from "clsx";
import SmallText from "components/ui/typograhpy/smallText/smallText";
import { useLightFont } from "utils/commonFunctions";

const defaultColours = ['#ADEBB3', '#B5C7EB', '#895129', '#98a869', '#ccccff']; // Can add more to this or have it data driven else where

interface ProfileIconProps {
  firstName: string;
  lastName: string;
  colour?: string; // in hex format
}

const defaultColour = defaultColours[Math.floor((Math.random()*defaultColours.length))]; // pick a random colour

const ProfileIcon = ({
  firstName,
  lastName,
  colour,
}: ProfileIconProps) => {

  const backgroundColor = colour ? colour : defaultColour;
  const fontClass = useLightFont(backgroundColor) ? styles.white : styles.black;
  return (
    <div style={{backgroundColor: backgroundColor}} className={styles.userInitial}>
      <SmallText className={cn(styles.transparent, fontClass)}>{`${firstName.substring(0, 1)}${lastName.substring(0, 1)}`}</SmallText>
    </div>
  );
};

export default ProfileIcon;
