import { FC, ReactNode } from "react";
import { ReactComponent as HomeLogo } from "assets/images/icons/icon-home.svg";
import { ReactComponent as ProjectsLogo } from "assets/images/icons/icon-projects.svg";
import { ReactComponent as FilesLogo } from "assets/images/icons/icon-files.svg";
import { ReactComponent as UsersLogo } from "assets/images/icons/icon-users.svg";
import { ReactComponent as EditUserLogo } from "assets/images/icons/icon-edit-user.svg";
import styles from "./leftNav.module.scss";
import SmallText from "components/ui/typograhpy/smallText/smallText";
import { useLocation } from 'react-router-dom';
import { RoutePaths } from "app/routing/routing";
import cn from 'clsx';
import { ButtonStyle, LinkButton } from "components/ui/button/button";

interface NavIconProps {
  icon: ReactNode;
  iconLabel: string;
  isActive: boolean;
  route: RoutePaths;
}

const NavIcon = ({ icon, iconLabel, isActive, route }: NavIconProps) => {
  return (
    <LinkButton to={route} style={ButtonStyle.None} className={cn(styles.iconContainer)}>
      <div className={cn(styles.icon, isActive && styles.isActive)}>{icon}</div>
      <SmallText>{iconLabel}</SmallText>
    </LinkButton>
  );
};

const LeftNav: FC = () => {
  const location = useLocation();

  return (
    <div className={styles.leftNavContainer}>
      <NavIcon route={RoutePaths.Home} icon={<HomeLogo />} iconLabel="Home" isActive={location.pathname === RoutePaths.Home} />
      <NavIcon route={RoutePaths.Projects} icon={<ProjectsLogo />} iconLabel="Projects" isActive={location.pathname === RoutePaths.Projects} />
      <NavIcon route={RoutePaths.Users} icon={<UsersLogo />} iconLabel="Users" isActive={location.pathname === RoutePaths.Users} />
      <NavIcon route={RoutePaths.Files} icon={<FilesLogo />} iconLabel="Files" isActive={location.pathname === RoutePaths.Files}/>
      <NavIcon route={RoutePaths.Admin} icon={<EditUserLogo />} iconLabel="Admin" isActive={location.pathname === RoutePaths.Admin}/>
    </div>
  );
};

export default LeftNav;
