import { FC, ReactNode } from "react";
import cn from "clsx";
import styles from "./textField.module.scss";
import { FieldErrors, UseFormRegister } from "react-hook-form";

interface TextFieldProps {
  name: string;
  type: "email" | "password" | "text" | "textArea";
  register: UseFormRegister<any>;
  errors?: FieldErrors<any>;
  validationSchema?: any;
  label?: string;
  required?: boolean;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
  fieldIcon?: ReactNode;
}

export const TextField: FC<TextFieldProps> = ({
  name,
  label,
  required,
  type,
  placeholder,
  className,
  labelClassName,
  fieldClassName,
  register,
  errors,
  validationSchema,
  fieldIcon,
}) => {
  return (
    <div className={cn(styles.textField, className)}>
      {(label || required) && (
        <label htmlFor={name} className={cn(styles.label, labelClassName)}>
          {label}
          {required && <span className={styles.required}>&emsp;</span>}
        </label>
      )}
      <div className={cn(styles.inputFieldContainer, fieldClassName)}>
        {type === "textArea" ? (
          <textarea
            {...register(name as `${string}`, validationSchema)}
            placeholder={placeholder}
            className={cn(styles.field, fieldClassName)}
          />
        ) : (
          <input
            {...register(name as `${string}`, validationSchema)}
            type={type}
            placeholder={placeholder}
            className={cn(styles.field, fieldClassName)}
          />
        )}
        {fieldIcon}
      </div>
      {errors && (
        <div className={styles.alertContainer}>
          <span className={styles.alert}>{errors[name]?.message}</span>
        </div>
      )}
    </div>
  );
};

export default TextField;
