import React, { useEffect, useState } from "react";
import styles from "./projectTile.module.scss";
import { ReactComponent as ThreeDotIcon } from "assets/images/icons/icon-threeDot.svg";
import { ReactComponent as UsersIcon } from "assets/images/icons/icon-users.svg";
import SubHeading from "components/ui/typograhpy/subheading/subheading";
import BodyText from "components/ui/typograhpy/bodyText/bodyText";
import SmallText from "components/ui/typograhpy/smallText/smallText";

interface ProjectTileProps {
  project: any;
}

export const ProjectTile = ({ project }: ProjectTileProps) => {
  return <div className={styles.projectTile}>
    <div className={styles.projectMeta}>
      <ThreeDotIcon className={styles.menuIcon} />
      <div className={styles.projectMetaText}>
        <SubHeading>Project Title</SubHeading>
        <BodyText>Owner</BodyText>
        <BodyText>Created date</BodyText>
      </div>
    </div>
    <div className={styles.projectBottomInfo}>
      <div className={styles.projectAccess}>
        <UsersIcon />
        <SmallText isBold={true}>Group Access</SmallText>
      </div>
      <div className={styles.projectInfo}>
        <SmallText isBold={true}>Project Info</SmallText>
      </div>
    </div>

  </div>;
};
