import { ReactNode, useState } from 'react';
import s from "./tab.module.scss";
import cn from 'clsx';
import BodyText from 'components/ui/typograhpy/bodyText/bodyText';

interface TabProps {
  tabLabel: string,
  icon?: ReactNode,
  onClick?: (event: any) => void,
  isSelected?: boolean,
}

export const Tab = ({
  tabLabel,
  icon,
  onClick,
  isSelected
}: TabProps) => {

  return (
    <div id={`nav-${tabLabel}`} className={cn(s.tabContainer, isSelected ? s.selected : null)} onClick={onClick}>
      {icon}
      <BodyText>{tabLabel}</BodyText>
    </div>
  );
};