import { ReactComponent as SearchIcon } from "assets/images/icons/icon-search.svg";
import cn from "clsx";
import { ContactsTable } from "components/contactsTable/contactsTable";
import Layout from "components/layout/Layout";
import { FilterSort } from "components/shared/filterSort/filterSort";
import { Button, ButtonStyle } from "components/ui/button/button";
import { DropdownOption } from "components/ui/optionDropdown/optionDropdown";
import TextField from "components/ui/textField/textField";
import { useForm } from "react-hook-form";
import styles from "./contacts.module.scss";
import { fetchContactsList } from "actions/contactActions";
import { useEffect, useState } from "react";
import { User } from "actions/lib/types";


const contactFilterOptions = [
  {
    label: "Internal",
    value: "internal",
  },
  {
    label: "Shared",
    value: "shared",
  },
];
const contactSortOptions = [
  {
    label: "Newest",
    value: "newest",
  },
  {
    label: "Oldest",
    value: "oldest",
  },
];

interface SearchFormFields {
  searchString: string;
}


export const Contacts = () => {
  
  const [contactsList, setContactsList] = useState<Array<User>>([]);
  
  useEffect(() => {
    updateContactsList();
  }, []);
  
  const updateContactsList = async () => {
    try {
      const result = await fetchContactsList();
      result != null ? setContactsList(result) : setContactsList([]);
    } catch (error) {
      console.error('Error fetching data:', error);
      setContactsList([]);
    }
  };
  
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SearchFormFields>();

  const handleFilterChange = (option: DropdownOption) => {
    console.log("Filter: ", option.value);
  };
  const handleSortChange = (option: DropdownOption) => {
    console.log("Sort:", option.value);
  };
  
  function handleAddContact() {
    console.log("Add Contact");
  }

  return (
    <Layout>
      <div className={styles.contactsPageContainer}>

        <div className={styles.header}>
          <div className={cn(styles.headerRow, styles.firstHeaderRow)}>
            <h1>{contactsList?.length} Contacts</h1>
            <Button
              style={ButtonStyle.Primary}
              className={styles.addContactButton}
              onClick={() => undefined}
              type={"button"}
            >
              + Add Contact
            </Button>
            <Button
              style={ButtonStyle.Secondary}
              className={styles.addContactButton}
              onClick={() => undefined}
              type={"button"}
            >
              Import from CSV
            </Button>
          </div>
          <div className={cn(styles.headerRow, styles.secondHeaderRow)}>
            <p>TODO: Pagination (Ticket T-321)</p>
            <FilterSort
              handleFilterChange={handleFilterChange}
              handleSortChange={handleSortChange}
              filterOptions={contactFilterOptions}
              sortOptions={contactSortOptions}
            />
            <TextField
              className={styles.searchBar}
              name={"Search"}
              placeholder={"Search user"}
              type={"text"}
              register={register}
              fieldIcon={<SearchIcon />}
            />
          </div>
        </div>

        <div className={styles.contactsTable}>
          <ContactsTable tableData={contactsList} setTableData={setContactsList} />
        </div>
      </div>
    </Layout>
  );
};