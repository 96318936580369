import { ReactNode, useState } from "react";
import s from "./mapLayer.module.scss";
import { ReactComponent as EyeIcon } from "assets/images/icons/icon-eye.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/icon-edit.svg";
import { ReactComponent as GearIcon } from "assets/images/icons/icon-gear.svg";
import { ReactComponent as LockIcon } from "assets/images/icons/icon-lock.svg";
import { ReactComponent as TrashIcon } from "assets/images/icons/icon-trash.svg";
import cn from 'clsx';
import BodyText from "components/ui/typograhpy/bodyText/bodyText";

interface MapLayerProps {
  layerName: string,
  boxColor?: string,
  boxBorderColor?: string,
  hasHover?: boolean,
  onEditClick?: (event: any) => void,
}

export const MapLayer = ({
  layerName,
  boxColor,
  boxBorderColor,
  hasHover = true,
  onEditClick,

}: MapLayerProps) => {

  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div id={layerName} className={s.mapLayerContainer} onMouseEnter={() => hasHover ? setIsHovered(true) : null} onMouseLeave={() => hasHover ? setIsHovered(false): null} onClick={(e) => e.stopPropagation()}>
      <div className={s.box} style={{backgroundColor: boxColor, borderColor: boxBorderColor}} />
      <BodyText className={s.mapLayerNameContainer}>{layerName}</BodyText>
      <div className={cn(s.buttonContainer, isHovered ? s.hovered : null)} >
        <EyeIcon />
        <EditIcon onClick={onEditClick} id={`edit-${layerName}`} />
        <LockIcon />
        <TrashIcon />
        <GearIcon />
      </div>
    </div>
  );
};
