import { _mockDataForLMapColours } from "pages/map/mockData/mockDataForColours";
import { _mockDataForLayers } from "pages/map/mockData/mockDataForLayers";


const fetchMapLayers = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForLayers; 
  }
  catch {
    return [];
  }
};

const fetchMapColours = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForLMapColours; 
  }
  catch {
    return [];
  }
};

export { fetchMapLayers, fetchMapColours };