import { MapLayer, MapLayerGroup } from "actions/lib/types";

export const _mockDataForLayers: Array<MapLayer | MapLayerGroup> = [
  {
    layerName: "Brampton Natural Heritage",
    boxColor: "lime",
    boxBorderColor: "green"
  },
  {
    groupName: "Ontario Costco Locations",
    layers: [
      {
        layerName: "Oakville Costco Points",
        boxColor: "darkblue",
        boxBorderColor: "darkred"
      },
      {
        layerName: "Scarborough Costco Points",
        boxColor: "darkblue",
        boxBorderColor: "darkred"
      }
    ]
  },
  {
    layerName: "20-08-04 Ontario Road Network",
    boxColor: "red",
    boxBorderColor: "black"
  },
  {
    layerName: "Oakville Address Points",
    boxColor: "coral",
    boxBorderColor: "blcornflowerblueack"
  },
  {
    layerName: "Mapbox_file_335",
    boxColor: "purple",
    boxBorderColor: "fuchsia"
  }
];
