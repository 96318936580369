import { FC, ReactNode } from "react";

import styles from "./bodyText.module.scss";

interface BodyTextProps {
  children: ReactNode;
  className?: string;
}

const BodyText = ({ children, className }: BodyTextProps) => {
  return <p className={className}>{children}</p>;
};

export default BodyText;
