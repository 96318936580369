import SmallText from "components/ui/typograhpy/smallText/smallText";
import styles from "./contactsTableRow.module.scss";
import cn from "clsx";
import { Button, ButtonStyle } from "components/ui/button/button";
import { Checkbox } from "components/ui/checkbox/checkbox";
import { ReactComponent as CheckIcon } from "assets/images/icons/icon-check.svg";
import { ReactComponent as GreenCheckIcon } from "assets/images/icons/icon-check-green.svg";
import { ReactComponent as ExternalUserIcon } from "assets/images/icons/icon-external-user.svg";
import { ChangeEvent } from "react";
import { isExternalEmail } from "utils/commonFunctions";
import ProfileIcon from "components/profileIcon/profileIcon";

interface ContactsTableRowProps {
  firstName: string;
  lastName: string;
  email: string;
  iconHex: string;
  isAddedToOrganization: boolean;
  onCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
}

function inviteToOrganization() {
  // TODO: Add Logic to Send an Invite Here
  console.log("Invite!");
}

export const ContactsTableRow = ({
  firstName,
  lastName,
  email,
  iconHex,
  isAddedToOrganization,
  onCheckboxChange,
  isChecked
}: ContactsTableRowProps) => {

  return (
    <tr className={styles.contactsTableRow}>
      <td className={styles.checkboxCell}>
        <Checkbox 
          label={""} 
          onFieldChange={onCheckboxChange}
          isChecked={isChecked}
        />
      </td>
      <td className={styles.userIconCell}>
        <div className={styles.userIconRowContainer}>
          <ProfileIcon firstName={firstName} lastName={lastName} colour={iconHex}/>
          {isExternalEmail(email) && <ExternalUserIcon />} {firstName} {lastName}
        </div>
      </td>
      <td className={cn(styles.fontBlue, styles.emailCell)}>{email}</td>
      <td>
        <div className={styles.addToOrganizationButtonContainer}>
          <Button
            className={styles.addToOrganizationButton}
            style={ButtonStyle.None}
            onClick={inviteToOrganization}
          >
            {isAddedToOrganization ? <GreenCheckIcon /> : <CheckIcon />}
          </Button>
        </div>
      </td>
    </tr>
  );
};
