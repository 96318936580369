import { Project } from "actions/lib/types";

export const _mockDataForProjects: Array<Project> = [
  {
    id: 1,
    type: 1,
    title: "Project 1",
    url: "www.project1.com"
  },
  {
    id: 2,
    type: 2,
    title: "Project 2",
    url: "www.project2.com"
  },
  {
    id: 3,
    type: 1,
    title: "Project 3",
    url: "www.project3.com"
  }
];
