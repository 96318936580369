import { FC, ReactNode } from "react";

import styles from "./smallText.module.scss";
import cn from "clsx";

interface SmallTextProps {
  children: ReactNode;
  isBold?: boolean;
  className?: string;
}

const SmallText = ({ children, isBold, className }: SmallTextProps) => {
  return <p className={cn(styles.smallText, isBold && styles.emphasize, className)}>{children}</p>;
};

export default SmallText;
