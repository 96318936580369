import cn from "clsx";
import { ReactNode } from "react";
import styles from "./modalWrapper.module.scss";
import Modal from "react-modal";

interface ModalWrapperProps {
  children: ReactNode;
  isModalOpen: boolean;
  className?: string;
}

export const ModalWrapper = ({
  children,
  isModalOpen,
  className,
}: ModalWrapperProps) => {
  return (
    <Modal
      isOpen={isModalOpen}
      className={cn(styles.modalWrapper, className)}
      ariaHideApp={false}
    >
      {children}
    </Modal>
  );
};
