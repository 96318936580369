import { ReferenceDocumentStatus } from "actions/lib/types";

export const _mockDataForReferenceDocumentStatuses: Array<ReferenceDocumentStatus> = [
  {
    id: 1,
    name: "Appealed"
  },
  {
    id: 2,
    name: "Archived"
  },
  {
    id: 3,
    name: "Current"
  },
  {
    id: 4,
    name: "Draft"
  },
  {
    id: 5,
    name: "Issued"
  },
  {
    id: 6,
    name: "Official"
  }
];
