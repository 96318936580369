import { User } from "actions/lib/types";

export const _listModalWrapperMockData: Array<User> = [
  {
    id: 50,
    firstName: "Daniel",
    lastName: "Martinet",
    email: "d.martinet@districtcity.com",
    iconHex: "#009BD6"
  },
  {
    id: 1,
    firstName: "Atilla",
    lastName: "Tran",
    email: "a.tran@districtcity.com",
    iconHex: "#009BD6"
  },
  {
    id: 4,
    firstName: "Lacey",
    lastName: "Killmaus",
    email: "l.killmaus@districtcity.com",
    iconHex: "#FBC439"
  },
  {
    id: 8,
    firstName: "Mani",
    lastName: "Romero",
    email: "m.romero@districtcity.com",
    iconHex: "#009BD6"
  },
  {
    id: 9,
    firstName: "Markus",
    lastName: "Romero",
    email: "markus.romero@districtcity.com",
    iconHex: "#A735AA"
  },
  {
    id: 19,
    firstName: "Leslie",
    lastName: "Thomas",
    email: "l.thomas@districtcity.com",
    iconHex: "#009BD6"
  },
];
