import { RoutePaths } from 'app/routing/routing';
import cn from "clsx";
import { LinkButton } from 'components/ui/button/button';
import styles from './navDropdownMenu.module.scss';

interface NavDropdownMenuOption {
  label: string;
  routeToDestination: RoutePaths;
  className?: string; 
}

interface NavDropdownMenuProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  options: Array<NavDropdownMenuOption>;
  className?: string;
}

const NavDropdownMenu = ({ isOpen, setIsOpen, options, className }: NavDropdownMenuProps) => {

  // Close Dropdown When an Option is Selected
  const handleOptionSelect = () => {
    setIsOpen(false);
  }

  return (
    <div>
      {isOpen && (
        <div className={cn(styles.navDropdownMenu, className, styles.dropshadow)}>
          {options.map((dropdownOption) => {
            return (
              <div key={`NavDropdownMenu-${dropdownOption.label}-Option`} onClick={handleOptionSelect}>
                <LinkButton
                  to={dropdownOption.routeToDestination}
                  className={styles.navDropdownOption}
                >
                  {dropdownOption.label}
                </LinkButton>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NavDropdownMenu;