import { ReactNode } from "react";
import s from "./numberSelect.module.scss";
import { ReactComponent as ArrowDownIcon } from "assets/images/icons/icon-arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/images/icons/icon-arrow-up.svg";
import cn from 'clsx';
import BodyText from "../typograhpy/bodyText/bodyText";

interface NumberSelectProps {
  value: number,
  onChangeIncrease: (event: any) => void,
  onChangeDecrease: (event: any) => void,
  containerClass?: string,
}

export const NumberSelect = ({
  value,
  onChangeIncrease,
  onChangeDecrease,
  containerClass,
}: NumberSelectProps) => {
  
  return (
    <div className={cn(s.numberContainer, containerClass)}>
      <BodyText className={s.number}>{value}px</BodyText>
      <ArrowDownIcon onClick={onChangeDecrease}/>
      <ArrowUpIcon onClick={onChangeIncrease}/>
    </div>
  );
};
