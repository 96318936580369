import { ReactNode, useState } from "react";
import s from "./mapLayerGroup.module.scss";
import { ReactComponent as ArrowUpIcon } from "assets/images/icons/icon-arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "assets/images/icons/icon-arrow-down.svg";
import BodyText from "components/ui/typograhpy/bodyText/bodyText";

interface MapLayerGroupProps {
  groupName: string;
  children?: ReactNode;
}

export const MapLayerGroup = ({
  groupName,
  children,
}: MapLayerGroupProps) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div onClick={() => setIsOpen(!isOpen)} className={s.mapLayerGroupContainer}>
      <BodyText>{groupName}</BodyText>
      {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      {isOpen && children}
    </div>
  );
};
