import { FC } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Home } from "pages/home/home";
import { Login } from "pages/login/login";
import { SignUp } from "pages/signUp/signUp";
import { Upload } from "pages/upload/upload";
import { EncryptedContent } from "pages/encryptedContent/encryptedContent";
import { useAppState } from "context";
import { StyleGuide } from "pages/styleGuide/styleGuide";
import { UserPage } from "pages/userPage/userPage";
import { EditUser } from "pages/editUser/editUser";
import { SiteCheckup } from "pages/siteCheckup/siteCheckup";
import { Projects } from "pages/projects/projects";
import { Map } from "pages/map/map";
import { Contacts } from "pages/contacts/contacts";
import { Admin } from "pages/admin/admin";
import { Study } from "pages/admin/subpages/study/study";
import { Organization } from "pages/admin/subpages/organization/organization";

export enum RoutePaths {
  Home = "/",
  UserPage = "/user",
  EditUser = "/user-edit",
  Contacts = "/contacts",
  StyleGuide = "/style-guide",
  Login = "/login",
  SignUp = "/signUp",
  Upload = "/upload",
  EncryptedContent = "/encryptedContent",
  SiteCheckup = "/siteCheckup",
  Projects = "/projects",
  Users = "/users",
  Files = "/files",
  Map = "/map",
  Admin = "/admin",
  StudyForm = "/studyForm",
  Organization = "/organization",
}

const AuthenticatedRoutes = () => {
  return (
    <Switch>
      <Route path={RoutePaths.Projects}>
        <Projects />
      </Route>
      <Route path={RoutePaths.UserPage}>
        <UserPage />
      </Route>
      <Route path={RoutePaths.EditUser}>
        <EditUser />
      </Route>
      <Route path={RoutePaths.EncryptedContent}>
        <EncryptedContent />
      </Route>
      <Route path={RoutePaths.Upload}>
        <Upload />
      </Route>
      <Route path={RoutePaths.Map}>
        <Map />
      </Route>
      <Route path={RoutePaths.Contacts}>
        <Contacts />
      </Route>
      <Route path={RoutePaths.Home}>
        <Home />
      </Route>
    </Switch>
  );
};

export const Routing: FC = () => {
  const appState = useAppState();

  return (
    <Router>
      <Switch>
        <Route path={RoutePaths.Projects}>
          <Projects />
        </Route>
        <Route path={RoutePaths.Admin}>
          <Admin />
        </Route>
        <Route path={RoutePaths.StudyForm}>
          <Study />
        </Route>
        <Route path={RoutePaths.Organization}>
          <Organization />
        </Route>
        <Route path={RoutePaths.SiteCheckup}>
          <SiteCheckup />
        </Route>
        <Route path={RoutePaths.StyleGuide}>
          <StyleGuide />
        </Route>
        <Route path={RoutePaths.SignUp}>
          <SignUp />
        </Route>
        <Route path={RoutePaths.Login}>
          <Login />
        </Route>
        <Route
          render={({ location }) => {
            if (appState.Authenticating && !appState.Authenticated) {
              return <>null</>;
            } else if (appState.Authenticated) {
              return <AuthenticatedRoutes />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: RoutePaths.Login,
                    state: { from: location },
                  }}
                />
              );
            }
          }}
        />
      </Switch>
    </Router>
  );
};
