import { RoutePaths } from "app/routing/routing";
import { ReactComponent as MainLogo } from "assets/images/icons/district.svg";
import NavDropdownMenu from "components/navDropdownMenu/navDropdownMenu";
import BodyText from "components/ui/typograhpy/bodyText/bodyText";
import SmallText from "components/ui/typograhpy/smallText/smallText";
import ProfileIcon from "components/profileIcon/profileIcon";
import { useOutsideClick } from "hooks/useOutsideClick";
import { useState } from "react";
import styles from "./header.module.scss";

// Header for all pages
// Dumb component, so use stateless Function declaration
// no props
const Header = () => {
  const mockUserObject = {
    firstName: "David",
    lastName: "Gerrard",
    role: "User",
  };

  
  const navDropdownMenuOptionsForUser = [
    { label: "Account Setting", routeToDestination: RoutePaths.Home },
    { label: "Licenses and Add-Ons", routeToDestination: RoutePaths.Home },
    { label: "Contacts", routeToDestination: RoutePaths.Contacts },
  ];
  
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  
  const handleDropdownToggle = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  // Handle Clicks Outside the Dropdown Area
  const outsideClickRef = useOutsideClick(() => {
    setIsDropdownOpen(false);
  });
  
  return (
    <div ref={outsideClickRef}>
      <header className={styles.header}>
        <MainLogo className={styles.logo} />
        <div
          className={styles.userHeaderContainer}
          onClick={handleDropdownToggle}
        >
          <ProfileIcon firstName={mockUserObject.firstName} lastName={mockUserObject.lastName} />
          <div>
            <BodyText>
              <SmallText className={styles.name} isBold={true}>{mockUserObject.firstName} {mockUserObject.lastName}</SmallText>
            </BodyText>
            <SmallText>{mockUserObject.role}</SmallText>
          </div>
        </div>
      </header>
      <div>
        {isDropdownOpen && (
          <NavDropdownMenu
            isOpen={isDropdownOpen}
            setIsOpen={setIsDropdownOpen}
            options={navDropdownMenuOptionsForUser}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
