import { ReferenceDocumentType } from "actions/lib/types";

export const _mockDataForReferenceDocumentTypes: Array<ReferenceDocumentType> = [
  {
    id: 1,
    name: "Community Plan"
  },
  {
    id: 2,
    name: "Concept Plan"
  },
  {
    id: 3,
    name: "DC Background Study"
  }
];
