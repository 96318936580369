import { ReactNode } from "react";
import s from "./mapPanel.module.scss";
import cn from 'clsx';
import { Button, ButtonStyle } from "components/ui/button/button";
import SmallHeading from "components/ui/typograhpy/smallHeading/smallHeading";
import { PanelHeader } from "components/panelHeader/panelHeader";
import { CloseButton } from "components/shared/closeButton/closeButton";

interface MapPanelProps {
  tabs?: string[],
  hasCloseButton?: boolean,
  children?: ReactNode | string;
  containerClass?: string,
  bodyClass?: string,
  isHidden?: boolean,
  selectedTab?: string,
  isFirstTab?: boolean,
  isLastTab?: boolean,
  onTabClick?: (event: any) => void,
  onLeftArrowClick?: () => void,
  onRightArrowClick?: () => void,
  onCloseClick?: () => void,
}

export const MapPanel = ({
  tabs = [],
  children,
  containerClass,
  bodyClass,
  selectedTab,
  hasCloseButton = false,
  onTabClick,
  onLeftArrowClick,
  onRightArrowClick,
  onCloseClick,
  isHidden = false,
  isFirstTab = false,
  isLastTab = false,
}: MapPanelProps) => {

  return (
    <div className={cn(containerClass, isHidden ? 'hide' : null, s.mapPanelContainer)}>
      <div className={cn(s.header, tabs.length > 0 ? null : s.noBorder)}>
        {hasCloseButton && <CloseButton onCloseClick={onCloseClick} />}
        { tabs.length > 0 && <PanelHeader childrenClass={s.tabContainer} isLastTab={isLastTab} isFirstTab={isFirstTab} onLeftArrowClick={onLeftArrowClick} onRightArrowClick={onRightArrowClick}>
          {tabs.map((tab, i) => {
            return (
              <Button id={`tab-${i}`} key={i} style={ButtonStyle.None} onClick={onTabClick} className={cn(s.tabName, selectedTab === tabs[i] || (!selectedTab && i === 0) ? s.selectedTab : null)}>
                <SmallHeading>
                  {tab}
                </SmallHeading>
              </Button>
            );
          })}
        </PanelHeader>}
      </div>
      <div className={cn(s.body, bodyClass)}>
        {children}
      </div>
    </div>
  );
};
