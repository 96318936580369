
import { FC, ReactNode } from "react";
import cn from 'clsx';
import styles from "./subheading.module.scss";

interface SubHeadingProps {
  className?: string;
  children: ReactNode;
}

const SubHeading = ({ children, className }: SubHeadingProps) => {
  return (
    <h3 className={cn(className)}>{children}</h3>
  );
};

export default SubHeading;
