import { ReactNode } from "react";
import cn from "clsx";
import { convertYesNoToBool, isUndefined } from "utils/commonFunctions";
import s from "./toggle.module.scss";
import { RadioField, RadioFieldStyle } from "components/ui/radioField/radioField";

interface ToggleBooleanProps {
  label?: ReactNode;
  required?: boolean;
  className?: string;
  labelClassName?: string;
  groupName: string;
  value?: boolean;
  onChange?: (valueToSet: string | boolean, groupName: string) => void;
  onClick?: (valueToSet: string | boolean, groupName: string) => void;
  buttonIcons?: Array<JSX.Element>;
  errorMessage?: ReactNode;
  children?: ReactNode;
}

export const ToggleBoolean = ({
  label,
  groupName,
  className,
  value,
  onChange,
  onClick,
  buttonIcons,
  errorMessage,
  children,
}: ToggleBooleanProps) => {
  const onChangeBool = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(convertYesNoToBool(e.target.value), groupName);
  };

  const onClickBool = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    onClick && onClick(convertYesNoToBool(e.currentTarget.value), groupName);
  };

  return (
    <div>
      <div className={cn(s.toggleStyle, className)}>
        {label && (
          <div className={s.toggleLabel}>
            {label}
            {buttonIcons}
            {children}
          </div>
        )}
        <div className={cn(s.booleanOptions)}>
          <RadioField
            style={RadioFieldStyle.Pill}
            value="Yes"
            text={"Yes"}
            groupName={groupName}
            key={`TrueValue-${groupName}`}
            onChange={onChangeBool}
            onClick={onClickBool}
            checked={!isUndefined(value) && value}
          />
          <RadioField
            style={RadioFieldStyle.Pill}
            value="No"
            text={"No"}
            groupName={groupName}
            key={`FalseValue-${groupName}`}
            onChange={onChangeBool}
            onClick={onClickBool}
            checked={!isUndefined(value) && !value}
          />
        </div>
      </div>
      {errorMessage && <p className={s.errorMessage}>{errorMessage}</p>}
    </div>
  );
};
