import Layout from "components/layout/Layout";
import styles from "./organization.module.scss";
import { OrganizationForm } from "components/organizationForm/organizationForm";

export const Organization = () => {

  return (
    <Layout>
      <div className={styles.appContainer}>
        <OrganizationForm />
      </div>
    </Layout>
  );
};
