import { FC } from "react";

import Header from "../header/header";
import Footer from "../footer/footer";
import ContentWrapper from "../contentWrapper/contentWrapper";
import styles from "./layout.module.scss";
import LeftNav from "components/shared/leftNav/leftNav";

const Layout: FC = ({ children }) => {
  return (
    <div className={styles.baseLayout}>
      <Header />
      <div className={styles.layoutWrapper}>
        <LeftNav />
        <ContentWrapper>{children}</ContentWrapper>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
