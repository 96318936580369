import { Button, ButtonStyle } from "components/ui/button/button";
import styles from "./dataTable.module.scss";
import cn from "clsx";
import { ReactComponent as CheckIcon } from "assets/images/icons/icon-check.svg";
import { UserGroup } from "actions/lib/types";
import BodyText from "components/ui/typograhpy/bodyText/bodyText";
import SmallText from "components/ui/typograhpy/smallText/smallText";
import { ReactNode } from "react";

export const DataTable = ({ tableData, tableHeaders }: any) => {
  return (
    <table className={styles.table}>
      {tableHeaders?.map((headerItem: { header: ReactNode }) => {
        return headerItem.header;
      })}
      <tbody>
        {tableData?.map((rowItem: UserGroup, index: number) => {
          return (
            <tr key={index}>
              <td>
                <div>
                  <BodyText>{rowItem.groupName}</BodyText>
                </div>
              </td>
              <td>
                <div className={styles.textCenter}>
                  <BodyText>
                    {rowItem.owner.firstName} {rowItem.owner.lastName}
                  </BodyText>
                </div>
              </td>
              <td>
                <div className={cn(styles.userIconRow, styles.textCenter)}>
                  <div className={styles.userIconRowContainer}>
                    {rowItem.members.slice(0, 3).map((member, index) => {
                      return (
                        <div
                          key={index}
                          className={styles.userIcon}
                          style={{ backgroundColor: member.iconHex }}
                        >
                          {member.firstName.substring(0, 1)}
                          {member.lastName.substring(0, 1)}
                        </div>
                      );
                    })}
                  </div>
                  <SmallText>{rowItem.members.length} users</SmallText>
                </div>
              </td>
              <td>
                <div className={styles.textCenter}>
                  <Button style={ButtonStyle.None}>
                    <CheckIcon />
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
