import { _mockDataForProjects } from "pages/admin/mockData/mockDataForProjects";
import { _mockDataForReferenceDocumentTypes } from "pages/admin/mockData/mockDataForReferenceDocumentTypes";
import { _mockDataForReferenceDocumentStatuses } from "pages/admin/mockData/mockDataForReferenceDocumentStatuses";
import { _mockDataForOrganizations } from "pages/admin/mockData/mockDataForOrganizations";
import { _mockDataForStudyAreaBoundaries } from "pages/admin/mockData/mockDataForStudyAreaBoundaries";

const fetchProjects = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForProjects;
  }
  catch {
    return [];
  }
};

const fetchReferenceDocumentTypes = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForReferenceDocumentTypes;
  }
  catch {
    return [];
  }
};

const fetchReferenceDocumentStatuses = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForReferenceDocumentStatuses;
  }
  catch {
    return [];
  }
};

const fetchOrganizations = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForOrganizations;
  }
  catch {
    return [];
  }
};

const fetchStudyAreaBoundaries = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForStudyAreaBoundaries;
  }
  catch {
    return [];
  }
};

export { fetchProjects, fetchReferenceDocumentTypes, fetchReferenceDocumentStatuses, fetchOrganizations, fetchStudyAreaBoundaries };