import { FC} from 'react';
import { withRouter} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from 'components/ui/button/button';
import { Field } from "components/ui/field/field";
import styles from "./organizationForm.module.scss";

export interface OrganizationFormFields {
  projectName: string;
}

export const OrganizationFormComponent: FC = () => {

  const { register, handleSubmit, formState: { errors }, watch } = useForm<OrganizationFormFields>();

  const handleOptionChange = (event: any) => {
    console.log("change option = ", event.value);
  };

  const onSubmit = async (values: OrganizationFormFields) => {
    //  Do nothing
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>

      <label>Organization</label>
      <hr className={styles.solid}></hr>

      <Field
        labelName={'Organization Type'}
        fieldName={'organizationType'}
        fieldType={'dropDown'}
        options={[]}
        handleOptionChange={handleOptionChange}
        customClassName={styles.halfLength}
      />

      <Field
        labelName={'Organization Name'}
        fieldName={'organizationName'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Field
        labelName={'Former Name'}
        fieldName={'formerName'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <label>CONTACT INFORMATION</label>
      <hr className={styles.solid}></hr>

      <Field
        labelName={'Phone'}
        fieldName={'phone'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.halfLength}
      />

      <Field
        labelName={'Email'}
        fieldName={'email'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Field
        labelName={'Website'}
        fieldName={'website'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <label>LOCATION</label>
      <hr className={styles.solid}></hr>

      <div className={styles.fieldGroup}>
        <Field
          labelName={'Unit Number'}
          fieldName={'unitNumber'}
          fieldType={'dropDown'}
          options={[]}
          handleOptionChange={handleOptionChange}
          fieldClassName={styles.leftField}
        />
        <Field
          labelName={'Street'}
          fieldName={'street'}
          fieldType={'dropDown'}
          options={[]}
          handleOptionChange={handleOptionChange}
        />
      </div>

      <div className={styles.fieldGroup}>
        <Field
          labelName={'Municiplaity / County'}
          fieldName={'municipality'}
          fieldType={'dropDown'}
          options={[]}
          handleOptionChange={handleOptionChange}
          fieldClassName={styles.leftField}
        />
        <Field
          labelName={'Region / District'}
          fieldName={'region'}
          fieldType={'dropDown'}
          options={[]}
          handleOptionChange={handleOptionChange}
        />
      </div>

      <div className={styles.fieldGroup}>
        <Field
          labelName={'Province / State'}
          fieldName={'province'}
          fieldType={'dropDown'}
          options={[]}
          handleOptionChange={handleOptionChange}
          fieldClassName={styles.leftField}
        />
        <Field
          labelName={'Country'}
          fieldName={'country'}
          fieldType={'dropDown'}
          options={[]}
          handleOptionChange={handleOptionChange}
        />
      </div>

      <Field
        labelName={'Postal Code / ZIP'}
        fieldName={'postalCode'}
        fieldType={'dropDown'}
        options={[]}
        handleOptionChange={handleOptionChange}
        customClassName={styles.halfLength}
      />

      <Button className={styles.submitButton} type="submit">
        Save
      </Button>
    </form>
  );
};

export const OrganizationForm = withRouter(OrganizationFormComponent);
