import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./appTile.module.scss";
import SubHeading from "components/ui/typograhpy/subheading/subheading";
import SmallText from "components/ui/typograhpy/smallText/smallText";

interface AppTileProps {
  app: any;
}

export const AppTile = ({ app }: AppTileProps) => {
  return <div className={styles.appTile}>
    <div className={styles.appMeta}>
      <div className={styles.appMetaText}>
        <SubHeading>{app.name}</SubHeading>
      </div>
    </div>
    <div className={styles.appBottomInfo}>
      <div className={styles.appAction}>

        <Link
          to={app.route}
        >
          <SmallText isBold={true}>Open</SmallText>
        </Link>
      </div>
    </div>

  </div>;
};
