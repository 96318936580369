import { User } from "actions/lib/types";

export const _mockDataForContactsTable: Array<User> = [
  {
    id: 1,
    firstName: "Atilla",
    lastName: "Tran",
    email: "a.tran@districtcity.com",
    iconHex: "#A735AA",
    addedToOrganization: true,
  },
  {
    id: 4,
    firstName: "Lacey",
    lastName: "Killmaus",
    email: "l.killmaus@districtcity.com",
    iconHex: "#424242",
    addedToOrganization: false,
  },
  {
    id: 5,
    firstName: "Margaret",
    lastName: "Illan",
    email: "illan.margaret@districtcity.com",
    iconHex: "#D9D9D9",
    addedToOrganization: false,
  },
  {
    id: 6,
    firstName: "Amber",
    lastName: "Fitzgerald",
    email: "amber_fitz@districtcity.com",
    iconHex: "#FBC439",
    addedToOrganization: true,
  },
  {
    id: 8,
    firstName: "Mani",
    lastName: "Romero",
    email: "mani.r@districtcity.com",
    iconHex: "#3F679F",
    addedToOrganization: true,
  },
  {
    id: 9,
    firstName: "David",
    lastName: "Evercroft",
    email: "d.ever.croft@districtcity.com",
    iconHex: "#D9D9D9",
    addedToOrganization: false,
  },
  {
    id: 10,
    firstName: "David",
    lastName: "Gerrard",
    email: "d.gerrard@districtcity.com",
    iconHex: "#009BD6",
    addedToOrganization: false,
  },
  {
    id: 11,
    firstName: "Kina",
    lastName: "Knopp",
    email: "k.k.nopp@districtcity.com",
    iconHex: "#1B2F4B",
    addedToOrganization: false,
  },
  {
    id: 12,
    firstName: "Nichie",
    lastName: "Enriquez",
    email: "n.enriquez@ellefsontech.com",
    iconHex: "#FF00D6",
    addedToOrganization: true,
  },
];
