import React, { useEffect, useState } from "react";
import Layout from "components/layout/Layout";
import { Button, ButtonStyle, LinkButton } from "components/ui/button/button";
import {
  apiHealthCheck,
  databaseHealthCheck,
} from "actions/lib/siteHealthCheckActions";
import { logoutUser, userIsAuthorized } from "actions/userActions";
import { RoutePaths } from "app/routing/routing";
import { SimpleDropdown } from "components/ui/simpleDropdown/simpleDropdown";
import { useUserState, useSetAppState } from "context";
import { useHistory } from "react-router-dom";
import styles from "./siteCheckup.module.scss";
import Title from "components/ui/typograhpy/title/title";
import SubHeading from "components/ui/typograhpy/subheading/subheading";
import Heading from "components/ui/typograhpy/heading/heading";
import cn from "clsx";
import BodyText from "components/ui/typograhpy/bodyText/bodyText";
import { DataModal } from "components/shared/dataModal/dataModal";
import { _tableModalWrapperMockData } from "./mockData/tableModalMockData";
import { _listModalWrapperMockData } from "./mockData/listModalMockData";
import {
  NestedModal,
  NestedModalData,
} from "components/shared/nestedModal/nestedModal";
import { TableType } from "components/shared/tableWrapper/tableWrapper";
import { _nestedModalWrapperMockData } from "./mockData/nestedModalMockData";
import SmallText from "components/ui/typograhpy/smallText/smallText";
import { UserInfo } from "components/ui/userInfo/userInfo";
import {
  DropdownOption,
  OptionDropdown,
} from "components/ui/optionDropdown/optionDropdown";
import { ReactComponent as DropdownIcon } from "assets/images/icons/icon-dropdown-arrow.svg";
import { ReactComponent as CheckIcon } from "assets/images/icons/icon-check.svg";
import { Checkbox } from "components/ui/checkbox/checkbox";

export const SiteCheckup = () => {
  const userState = useUserState();
  let connectApi = "Disconnected";
  let connectData = "Disconnected";
  const history = useHistory();
  const [isTableModalOpen, setIsTableModalOpen] = useState<boolean>(false);
  const [isListModalOpen, setIsListModalOpen] = useState<boolean>(false);
  const [isNestedModalOpen, setIsNestedModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();

  const handleUserRoleChange = (option: DropdownOption) => {
    console.log("Role:", option.value);
  };

  const handleSelectItem = (item: any) => {
    console.log("item", item);
    setSelectedItem(item);
  };

  const dropdownOptions = [
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.StyleGuide)}>
          Style Guide
        </a>
      ),
    },
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.SignUp)}>
          Signup
        </a>
      ),
    },
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.Login)}>
          Login
        </a>
      ),
    },
  ];

  const [connectedToApi, setConnectedToApi] = useState(false);
  const [connectedToDatabase, setConnectedToDatabase] = useState(false);

  useEffect(() => {
    const checkApiConnection = async () => {
      try {
        const result = await apiHealthCheck();
        if (result.data.connected) {
          setConnectedToApi(true);
        }
      } catch (err) {
        setConnectedToApi(false);
      }
    };

    const checkDatabaseConnection = async () => {
      try {
        const result = await databaseHealthCheck();
        if (result.data.connected) {
          setConnectedToDatabase(true);
        }
      } catch (err) {
        setConnectedToDatabase(false);
      }
    };

    checkApiConnection();
    checkDatabaseConnection();
  }, []);

  const setAppState = useSetAppState();

  const onLogout = async () => {
    await logoutUser();

    setAppState({ Authenticated: false, Authenticating: false });
    history.push(RoutePaths.Login);
  };

  if (connectedToApi) {
    connectApi = "Connected";
  }
  if (connectedToDatabase) {
    connectData = "Connected";
  }

  const dropdownOptionsAuthenticated = [
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.StyleGuide)}>
          Style Guide
        </a>
      ),
    },
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.Upload)}>
          Upload
        </a>
      ),
    },
    {
      option: (
        <a
          type="button"
          onClick={() => history.push(RoutePaths.EncryptedContent)}
        >
          Encrypted Content
        </a>
      ),
    },
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.UserPage)}>
          User Page
        </a>
      ),
    },
    {
      option: (
        <a id="logout" type="button" onClick={onLogout}>
          Logout
        </a>
      ),
    },
  ];

  const NestedDataGroupRender = ({ nestedData, handleSelectItem }: any) => {
    return (
      <div>
        {nestedData?.map((data: any, index: number) => {
          return (
            <Button
              className={styles.dataGroup}
              key={index}
              style={ButtonStyle.None}
              onClick={() => {
                handleSelectItem(data);
              }}
            >
              <BodyText>{data.nestedDataGroup.name}</BodyText>
            </Button>
          );
        })}
      </div>
    );
  };

  const NestedDataRender = ({ selectedItem }: any) => {
    return (
      <div>
        {selectedItem?.nestedData?.map((nestedData: any, index: any) => {
          return (
            <div key={index} className={styles.nestedDataTableRow}>
              <div className={styles.fullFlex}>
                <UserInfo userInfo={nestedData} showUserName={true} />
              </div>
              <div>
                <OptionDropdown
                  label={""}
                  className={styles.roleDropdown}
                  options={[
                    {
                      value: "projectManager",
                      label: "Project Manager",
                    },
                    {
                      value: "mapEditor",
                      label: "Map Editor",
                    },
                  ]}
                  handleOptionChange={handleUserRoleChange}
                  dropdownIcon={<DropdownIcon />}
                />
              </div>
              <div className={styles.accessButton}>
                <Button style={ButtonStyle.None}>
                  <CheckIcon />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Layout>
      <div className={styles.siteCheckupHeader}>
        <LinkButton style={ButtonStyle.None} to={RoutePaths.Home}>
          <Title>District Maps</Title>
        </LinkButton>
        <p>
          <b>Api:</b> {connectApi}
        </p>
        <p>
          <b>Database:</b> {connectData}
        </p>
      </div>
      <div className={styles.container}>
        <div className={cn(styles.componentContainer)}>
          <div className={cn(styles.typography, styles.innerContainer)}>
            <SubHeading>Typography</SubHeading>
            <Title>Title</Title>
            <Heading>Heading</Heading>
            <SubHeading>Sub-Heading</SubHeading>
            <BodyText>Body Text</BodyText>
          </div>
          <div className={styles.innerContainer}>
            <SubHeading>Buttons</SubHeading>
            <div className={styles.rowGapped}>
              <Button style={ButtonStyle.Primary}>Primary Button</Button>
              <Button style={ButtonStyle.Secondary}>Secondary Button</Button>
              <LinkButton to={""}>Link Button</LinkButton>
            </div>
          </div>
          <div className={styles.innerContainer}>
            <SubHeading>Forms</SubHeading>
            <div className={styles.rowGapped}>
              <Checkbox label={"Checkbox label"} onFieldChange={(value) => {console.log('Checkbox value', value);}} />
            </div>
          </div>
        </div>
        <div className={cn(styles.componentContainer)}>
          <div className={styles.innerContainer}>
            <SubHeading>Modals</SubHeading>
            <div className={styles.rowGapped}>
              <Button
                style={ButtonStyle.Primary}
                onClick={() => {
                  setIsTableModalOpen(true);
                }}
              >
                Data Modal - Table Type
              </Button>
              <Button
                style={ButtonStyle.Primary}
                onClick={() => {
                  setIsListModalOpen(true);
                }}
              >
                Data Modal - List Type
              </Button>
              <Button
                style={ButtonStyle.Primary}
                onClick={() => {
                  setIsNestedModalOpen(true);
                }}
              >
                Nested Modal
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DataModal
        tableTitle={"Create New Item"}
        isOpen={isTableModalOpen}
        fieldName={"item"}
        fieldLabel={"Item field:"}
        affirmationButtonLabel={"Create item"}
        tableLabel={"Select item to do something:"}
        tableData={_tableModalWrapperMockData}
        handleCloseTable={setIsTableModalOpen}
        tableType={TableType.Table}
        tableHeaders={[
          {
            header: (
              <th>
                <SmallText>Group name</SmallText>
              </th>
            ),
          },
          {
            header: (
              <th>
                <SmallText className={styles.textCenter}>Owner</SmallText>
              </th>
            ),
          },
          {
            header: (
              <th>
                <SmallText className={styles.textCenter}>Members</SmallText>
              </th>
            ),
          },
          {
            header: (
              <th>
                <SmallText className={styles.textCenter}>
                  Group access
                </SmallText>
              </th>
            ),
          },
        ]}
      ></DataModal>
      <DataModal
        tableTitle={"Edit List Item"}
        isOpen={isListModalOpen}
        fieldName={"item"}
        fieldLabel={"Item field:"}
        affirmationButtonLabel={"Save"}
        tableLabel={"Modify list item:"}
        tableData={_listModalWrapperMockData}
        handleCloseTable={setIsListModalOpen}
        listBlurb={
          "Instructions or a short description on information on the list."
        }
        tableType={TableType.List}
      ></DataModal>
      <NestedModal
        tableTitle={"Nested Modal Title"}
        isOpen={isNestedModalOpen}
        handleCloseTable={setIsNestedModalOpen}
        nestedData={_nestedModalWrapperMockData}
        ctaButtonLabel={"+ Add item group"}
        nestedDataGroupRender={
          <NestedDataGroupRender
            nestedData={_nestedModalWrapperMockData}
            handleSelectItem={handleSelectItem}
          />
        }
        nestedDataRender={<NestedDataRender selectedItem={selectedItem} />}
      ></NestedModal>
    </Layout>
  );
};
