import React, { useEffect, useState } from "react";
import styles from "./userInfo.module.scss";
import { User } from "actions/lib/types";
import SmallText from "../typograhpy/smallText/smallText";
import BodyText from "../typograhpy/bodyText/bodyText";
import cn from "clsx";

interface UseInfoProps {
  userInfo: User;
  showUserName?: boolean;
}

export const UserInfo = ({ userInfo, showUserName }: UseInfoProps) => {
  return (
    <div className={styles.userInfoContainer}>
      <div
        className={styles.userIcon}
        style={{ backgroundColor: userInfo.iconHex }}
      >
        <SmallText>
          {userInfo.firstName.substring(0, 1)}
          {userInfo.lastName.substring(0, 1)}
        </SmallText>
      </div>
      {showUserName && (
        <div>
          <BodyText className={styles.userLabel}>
            {userInfo.firstName} {userInfo.lastName}
          </BodyText>
          <SmallText className={cn(styles.fontBlue, styles.userLabel)}>
            {userInfo.email}
          </SmallText>
        </div>
      )}
    </div>
  );
};
