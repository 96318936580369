import { ReactNode } from "react";
import cn from 'clsx';

import styles from "./smallHeading.module.scss";

interface SmallHeadingProps {
  children: ReactNode;
  className?: string;
}

const SmallHeading = ({ 
  children,
  className,
}: SmallHeadingProps) => {
  return (
    <p className={className}>{children}</p>
  );
};

export default SmallHeading;
