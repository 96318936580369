import { ReactComponent as TrashIcon } from "assets/images/icons/icon-trash.svg";
import { ContactsTableRow } from "components/contactsTableRow/contactsTableRow";
import { Button, ButtonStyle } from "components/ui/button/button";
import SmallText from "components/ui/typograhpy/smallText/smallText";
import styles from "./contactsTable.module.scss";
import { ChangeEvent, useEffect, useState } from "react";
import { User } from "actions/lib/types";

interface ContactsTableProps {
  tableData: Array<User>;
  setTableData: React.Dispatch<React.SetStateAction<Array<User>>>;
}

export const ContactsTable = ({ tableData, setTableData }: ContactsTableProps) => {
  
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const handleCheckboxChange = (id: number, event: ChangeEvent<HTMLInputElement>) => {
    setSelectedRows(prevSelectedRows => {
      const updatedSelectedRows = new Set(prevSelectedRows);
      updatedSelectedRows.has(id) ? updatedSelectedRows.delete(id) : updatedSelectedRows.add(id);
      return updatedSelectedRows;
    });
  };

  const handleBatchDelete = () => {
    setTableData(prevData => prevData.filter(row => !selectedRows.has(row.id)));
    setSelectedRows(new Set()); // Clear selected rows after deletion
    
    // TODO: Add logic to delete selected contacts from the backend here
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>
            <Button
              onClick={handleBatchDelete}
              className={styles.batchDeleteButton}
              style={ButtonStyle.None}
            >
              <span><TrashIcon /></span> 
              <span>Batch Delete</span>
            </Button>
          </th>
          <th></th>
          <th></th>
          <th>
            <SmallText className={styles.textCenter}>
              {"Added to Organization"}
            </SmallText>
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(
          ({
            id,
            firstName,
            lastName,
            email,
            iconHex,
            addedToOrganization,
          }) => {
            return (
              <ContactsTableRow 
                key={id} 
                firstName={firstName} 
                lastName={lastName} 
                email={email} 
                iconHex={iconHex} 
                isAddedToOrganization={addedToOrganization ?? false}
                isChecked={selectedRows.has(id)}
                onCheckboxChange={(event) => handleCheckboxChange(id, event)} 
              />
            );
          }
        )}
      </tbody>
    </table>
  );
};
