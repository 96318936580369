import { MapLayer } from "components/mapLayer/mapLayer";
import { OptionDropdown } from "components/ui/optionDropdown/optionDropdown";
import { SliderSelect } from "components/ui/sliderSelect/sliderSelect";
import UseMapHook from "pages/map/mapHook";
import styles from "./editMapPanel.module.scss";
import { ReactComponent as ArrowDownIcon } from "assets/images/icons/icon-arrow-down.svg";
import { NumberSelect } from "components/ui/numberSelect/numberSelect";
import { Button } from "components/ui/button/button";
import { useEffect } from "react";

interface EditMapPanelProps {
  layerName: string;
  boxColour: string;
  boxBorderColour: string;
}

export const EditMapPanel = ({layerName, boxColour, boxBorderColour }: EditMapPanelProps) => {

  const { updateColourList, setBorderWidth, borderWidth, opacity, onChangeOpacity, colourList, fillColour, borderColour } = UseMapHook();

  useEffect(() => {
    updateColourList();
  }, []);

  const handleOptionChange = (event: any) => {
    console.log("change option = ", event.value);
  };

  const handleBorderIncrease = (event: any) => {
    setBorderWidth(borderWidth + 1);
  };

  const handleBorderDecrease = (event: any) => {
    setBorderWidth(borderWidth + -1);
  };

  return <div className={styles.editMapContainer}>
    <MapLayer layerName={layerName} boxColor={boxColour} boxBorderColor={boxBorderColour} hasHover={false} />
    <SliderSelect label='Opacity' value={opacity} onChangeValue={onChangeOpacity} sliderClass={styles.slider} />
    <OptionDropdown label={''} options={colourList} handleOptionChange={handleOptionChange} className={styles.editOptions} dropdownIcon={<ArrowDownIcon/>}/>
    <div className={styles.settingContainer}>
      <p>Fill colour</p>
      <div className={styles.colourContainer}>
        <p style={{color: fillColour}}>{fillColour}</p>
        <div className={styles.box} style={{backgroundColor: fillColour}} />
      </div>
    </div>
    <div className={styles.settingContainer}>
      <p>Border colour</p>
      <div className={styles.colourContainer}>
        <p style={{color: borderColour}}>{borderColour}</p>
        <div className={styles.box} style={{backgroundColor: borderColour}} />
      </div>
    </div>
    <div className={styles.settingBorderContainer}>
      <p>Border width</p>
      <NumberSelect value={borderWidth} onChangeDecrease={handleBorderDecrease} onChangeIncrease={handleBorderIncrease} containerClass={styles.borderWidthContainer}/>
    </div>
    <Button className={styles.applyContainer}><p>Apply</p></Button>
    <Button className={styles.applySaveContainer}><p>Save style</p></Button>
  </div>;
};