import { _mockDataForContactsTable } from "pages/contacts/mockData/mockDataForContactsTable";

const fetchContactsList = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForContactsTable; 
  }
  catch {
    return [];
  }
};

export { fetchContactsList };