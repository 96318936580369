import styles from "./checkbox.module.scss";
import { ChangeEvent, ReactElement } from "react";
import cn from "clsx";
import { ReactComponent as Checkmark } from "assets/images/icons/icon-blue-checkmark.svg";

interface CheckboxProps {
  label: string | ReactElement;
  isChecked?: boolean;
  onFieldChange: (arg0: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export const Checkbox = ({
  label,
  isChecked,
  onFieldChange,
  className,
}: CheckboxProps) => {

  return (
    <div className={cn(styles.customCheckbox, className)}>
      <label>
        <input
          type={"checkbox"}
          onChange={(event: any) => {
            onFieldChange(event);
          }}
        />
        <span className={styles.checkbox}>
          <Checkmark
            className={isChecked ? styles.checkboxActive : ""}
            // This element is purely decorative so
            // we hide it for screen readers
            aria-hidden="true"
          />
        </span>
        {label}
      </label>
    </div>
  );
};
