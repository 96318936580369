import cn from "clsx";
import styles from "./optionDropdown.module.scss";
import { ReactNode, useState } from "react";
import BodyText from "../typograhpy/bodyText/bodyText";
import { Button, ButtonStyle } from "../button/button";

export interface DropdownOption {
  label: string;
  value: string | number;
  base64?: string;
}

interface OptionDropdownProps {
  label: string;
  options: Array<DropdownOption>;
  dropdownIcon?: ReactNode;
  className?: string;
  handleOptionChange: (option: DropdownOption) => void;
}

export const OptionDropdown = ({
  label,
  options,
  dropdownIcon,
  className,
  handleOptionChange
}: OptionDropdownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOption>();

  return (
    <div
      onClick={() => {
        setDropdownOpen(!dropdownOpen);
      }}
      className={cn(styles.dropdown, className)}
    >
      <button className={cn(styles.dropdownButton)}>
        {label &&  `${label}:`}
        {selectedOption ? <span>{`${selectedOption.label}`}</span> : <span>{'None'}</span>}
        {dropdownIcon}
      </button>
      {dropdownOpen && (
        <div className={cn(styles.dropdownContent)}>
          {options.map((option, index) => {
            return (
              <Button onClick={() => {
                setSelectedOption(option);
                handleOptionChange(option);
              }} style={ButtonStyle.None} key={index}>
                <BodyText className={styles.dropdownOption}>
                  {option.label}
                </BodyText>
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const OptionDropdownWithImage = ({
  label,
  options,
  dropdownIcon,
  className,
  handleOptionChange
}: OptionDropdownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOption>();

  return (
    <div
      onClick={() => {
        setDropdownOpen(!dropdownOpen);
      }}
      className={cn(styles.dropdown, className)}
    >
      <button className={cn(styles.dropdownButton)}>
        {label &&  `${label}:`}
        {selectedOption ? <span>{`${selectedOption.label}`}</span> : <span>{'None'}</span>}
        {dropdownIcon}
      </button>
      {dropdownOpen && (
        <div className={cn(styles.dropdownContent, styles.dropdownContentImg)}>
          {options.map((option, index) => {
            return (
              <div className={styles.dropdownOptionImage} key={index}
                onClick={() => {
                  setSelectedOption(option);
                  handleOptionChange(option);
                }}>
                <img src={option.base64} alt="boundary" />
                <label>{option.label}</label>
              </div>
            );
          })}
          <div></div>
        </div>
      )}
    </div>
  );
};
