import React, { useEffect, useState } from "react";
import cn from "clsx";
import { ReactComponent as FilterIcon } from "assets/images/icons/icon-filter.svg";
import { ReactComponent as SortIcon } from "assets/images/icons/icon-sort.svg";

import styles from "./filterSort.module.scss";
import {
  DropdownOption,
  OptionDropdown,
} from "components/ui/optionDropdown/optionDropdown";

interface FilterSortProps {
  filterOptions?: Array<DropdownOption>;
  sortOptions?: Array<DropdownOption>;
  handleFilterChange: (option: DropdownOption) => void;
  handleSortChange: (option: DropdownOption) => void;
}

export const FilterSort = ({
  filterOptions,
  sortOptions,
  handleFilterChange,
  handleSortChange,
}: FilterSortProps) => {
  return (
    <div className={styles.filterSort}>
      {sortOptions && (
        <OptionDropdown
          options={sortOptions}
          label={"Sort"}
          className={styles.filterDropdown}
          handleOptionChange={handleSortChange}
          dropdownIcon={<SortIcon className={cn(styles.dropdownArrow)} />}
        />
      )}
      {filterOptions && (
        <OptionDropdown
          options={filterOptions}
          label={"Filter"}
          className={styles.filterDropdown}
          handleOptionChange={handleFilterChange}
          dropdownIcon={<FilterIcon className={cn(styles.dropdownArrow)} />}
        />
      )}
    </div>
  );
};
